import React, { useEffect, useState } from 'react';
import NextImage, { StaticImageData } from 'next/image';

type SSRImageProps = {
  url: string | Array<string> | StaticImageData;
  classes?: string;
  [x: string]: any;
};

export default function ImageLoaded({ url, classes, ...rest }: SSRImageProps) {
  const [loadedImage, setLoadedImage] = useState<HTMLImageElement | StaticImageData>();

  useEffect(() => {
    const loadImage = async () => {
      if (typeof url === 'string' || url instanceof Array) {
        const urls = typeof url === 'string' ? [url] : url;
        // we set the loaded image to the first image that loads
        const imagePromises = urls.map(
          (imgUrl: string) =>
            new Promise<HTMLImageElement>((resolve) => {
              const image = new Image();
              image.src = imgUrl;
              image.onload = () => resolve(image);
            }),
        );
        const res = await Promise.any(imagePromises);
        setLoadedImage(res);
      } else {
        setLoadedImage(url);
      }
    };

    loadImage();
  }, [url]);

  return loadedImage ? <NextImage src={loadedImage} className={classes} {...rest} /> : null;
}

ImageLoaded.defaultProps = {
  classes: '',
};
