import React, { ComponentType, useCallback, useMemo } from 'react';
import { useEvents } from '@events/EventsProvider';
import { CAPTURE_EMAIL_VIEWED } from '@constants/amplitudeEvents';
import useEmailCaptureConfig, { PromoConfig } from '@hooks/useEmailCaptureConfig';
import { BFCMPromoFlag, ComponentMappings } from '@customTypes/promos';
import { useRouter } from 'next/router';

export type PromoLogicProps = {
  bfCMPromoFlag: BFCMPromoFlag;
  promoConfig: PromoConfig;
  handleClick: () => void;
};

export default function withPromoLogic<T extends object>(
  WrappedComponent: ComponentType<T & PromoLogicProps>,
  source: keyof ComponentMappings | 'MarketingModule',
) {
  return function ComponentWithPromoLogic(props: T) {
    const router = useRouter();
    const { track } = useEvents();

    const componentName = useMemo(() => {
      if (source === 'MarketingModule') {
        return router.pathname === '/' ? 'HomePageMarketingModule' : 'SRPMarketingModule';
      }

      return source;
    }, [router.pathname]);

    const { bfCMPromoFlag, promoConfig } = useEmailCaptureConfig({
      componentName,
    });

    const handleClick = useCallback(() => {
      // Add a hashtag to the URL without causing a page reload or re-render.
      // This is used to track which MarketingModule was clicked.
      router.push(
        {
          pathname: router.pathname,
          query: { ...router.query, 'marketing-module': componentName },
        },
        undefined,
        { shallow: true },
      );

      track(CAPTURE_EMAIL_VIEWED, { source: componentName });
    }, [router, track, componentName]);

    return (
      <WrappedComponent
        {...props}
        bfCMPromoFlag={bfCMPromoFlag}
        promoConfig={promoConfig}
        handleClick={handleClick}
      />
    );
  };
}
