import { useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { store } from '@context/store';
import { isAValidEmail } from '@helpers/emailValidation';
import { getCookieValue, setCookie } from '@helpers/cookies';
import { sendEmailToIterable } from '@utils/services';
import toggleErrorPopup from '@helpers/toggleErrorPopup';
import { capitalize } from 'lodash';
import { useEvents } from '@events/EventsProvider';
import { EMAIL_CAPTURED } from '@constants/amplitudeEvents';

export default function useMarketingModule(handleClick: () => void) {
  const { track } = useEvents();
  const { dispatch, state } = useContext(store);
  const { userLocation } = state;
  const router = useRouter();

  const [currentStep, setCurrentStep] = useState(1);
  const [email, setEmail] = useState('');
  const [, setIsFormSubmitted] = useState(false);

  const spaSRPLink = useMemo(() => {
    if (userLocation) {
      const { name, id } = userLocation;
      const cityName = name.split(',')[0];
      return `/hotel-day-passes/${cityName}-${id}`;
    }
    return '/hotel-day-passes/Los-Angeles-4';
  }, [userLocation]);

  const handleCTAClick = () => router.push(spaSRPLink);

  const handleSubmit = async () => {
    if (!email || !isAValidEmail(email)) return;

    try {
      handleClick(); // track event

      track(EMAIL_CAPTURED, {
        email,
        source: router.pathname === '/' ? 'HomePageMarketingModule' : 'SRPMarketingModule',
      });

      await sendEmailToIterable(email);

      setCookie('mails_opt_in', 'true', 30);
      setIsFormSubmitted(true);
      setCurrentStep(2);
    } catch (error: any) {
      toggleErrorPopup(
        capitalize(error.response.data.errors[0].msg) || 'Email is not valid',
        dispatch,
      );
    }
  };

  useEffect(() => {
    if (getCookieValue('mails_opt_in') === 'true') {
      setCurrentStep(3);
    }
  }, []);

  return {
    currentStep,
    setEmail,
    handleSubmit,
    handleCTAClick,
    spaSRPLink,
    email,
  };
}
