import React, { useState, useMemo, useRef, MutableRefObject, Dispatch, RefObject } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import HotelApi from '@customTypes/hotel-api';
import { PopularHotel } from '@customTypes/popular-hotel-data';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper';
import Chevron from '@solar/assets/images/directional/chevron';
import { useCookies } from 'react-cookie';
import { productCategoriesMap, normalizedProductTypeName } from '@constants/productType';
import useFavorites from '@hooks/useFavorites';
import useSessionStorage from '@hooks/useSessionStorage';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { unionizeFavorites } from '@helpers/addHotelToFavorites';
import FavoritesToastMessage from '@components/MyFavorites/ToastMessage/FavoritesToastMessage';
import RoutingPath from '@constants/routingPath';
import stopwatchIcon from '@assets/images/stopwatch-icon.svg';
import useHotSpot from '@hooks/useHotSpot';
import { parseISO } from 'date-fns';
import { HotSpotMin } from '@customTypes/hot-spot';
import cn from '@helpers/className';
import HotelCard from './HotelCardV2';
import 'swiper/css';

type Hotel = {
  name: string;
  url: string;
  rating: string;
  reviews: number;
  location: string;
  vibe: string;
  productCategories: Array<string>;
  hotelImages: Array<string>;
  isHotSpot: boolean;
  derived: PopularHotel | HotelApi;
};

type HotelCardCollectionProps = {
  className?: string;
  showSeeAll?: boolean;
  onSeeAll?: React.MouseEventHandler;
  onHotelClick?: (h: Hotel) => Promise<any>;
  title: string;
  hotels: Array<Hotel>;
  innerRef?: RefObject<HTMLElement>;
};

// ---------------------------------------------------------------------
// MapPopularHotels ----------------------------------------------------
// ---------------------------------------------------------------------
// Maps the PopularHotel Type to the Hotel type (above) to be consumed by
// the hotel card.
// ---------------------------------------------------------------------
export const MapPopularHotels = (popularHotels: Array<PopularHotel>): Array<Hotel> =>
  popularHotels.map((p) => ({
    name: p.name,
    url: p.url,
    rating: p.avg_rating || '',
    reviews: p.reviews_count || 0,
    location: [p.city_name, p.state_name].join(', '),
    vibe: '',
    productCategories: [] as Array<string>,
    hotelImages: p.image.map((i) => i.picture.details.url),
    derived: p,
    isHotSpot: !!p.hotSpot,
  }));

// ---------------------------------------------------------------------
// MapHotelApi ---------------------------------------------------------
// ---------------------------------------------------------------------
// Maps the "HotelApi" Type to the Hotel type (above) to be consumed by
// the hotel card. The HotelApi type is the returned data from our
// search/algolia endpoint.
// ---------------------------------------------------------------------
export const MapHotelApi = (hotels: Array<HotelApi>): Array<Hotel> =>
  hotels.map((p) => ({
    name: p.name,
    url: p.url,
    rating: p.avgRating || '',
    reviews: p.reviewsCount || 0,
    location: [p.cityName, p.code].join(', '),
    vibe: p.vibes.primary || '',
    productCategories: ((products) => {
      const inverseProductCategoriesMap = (
        Object.keys(productCategoriesMap) as Array<string>
      ).reduce<Record<string, string>>((accu, k) => {
        productCategoriesMap[k].forEach((pt) => {
          // eslint-disable-next-line no-param-reassign
          accu[pt] = k;
        });
        return accu;
      }, {});

      const uniqueProductCategories = new Set<string>();
      products.forEach((product) => {
        uniqueProductCategories.add(
          inverseProductCategoriesMap[normalizedProductTypeName[product.product_type_name]],
        );
      });
      return Array.from(uniqueProductCategories).filter((pc) => pc !== undefined);
    })(p.products),
    hotelImages: p.image.map((i) => i.picture.details.url),
    derived: p,
    isHotSpot: !!p.hotSpot,
  }));

export default function HomePageHotelCardCollection({
  className,
  title,
  hotels,
  showSeeAll,
  onSeeAll,
  onHotelClick,
  innerRef,
}: HotelCardCollectionProps) {
  // -------------------------------------------------------------------
  // UI State ----------------------------------------------------------
  // -------------------------------------------------------------------
  const rootRef = innerRef || useRef(null);
  const desktopSwiper = useRef<SwiperType>();
  const mobileSwiper = useRef<SwiperType>();
  const [isDesktopSwiperBeginning, setIsDesktopSwiperBeginning] = useState(true);
  const [isDesktopSwiperEnd, setIsDesktopSwiperEnd] = useState(false);
  const [isMobileSwiperBeginning, setIsMobileSwiperBeginning] = useState(true);
  const [isMobileSwiperEnd, setIsMobileSwiperEnd] = useState(false);
  const { getBadgeText } = useHotSpot();
  const router = useRouter();

  // DesktopHotels is simply hotels groupd into buckets of 8 so we can display
  // them as a Grid instead of a single horizontal swiper
  const desktopHotels = useMemo(() => {
    const bucket: Array<Array<Hotel>> = Array(...Array(Math.ceil(hotels.length / 8))).map(() => []);
    return hotels.reduce((pages, hotel, index) => {
      const page = Math.floor(index / 8);
      pages[page].push(hotel);
      return pages;
    }, bucket);
  }, [hotels]);

  // -------------------------------------------------------------------
  // Favorites !!! -----------------------------------------------------
  // -------------------------------------------------------------------
  // Existing favorites implementation is...not great.
  // However it's out of scope for the use-case project to refactor.
  // Below are all of the necessary dependencies to implement favorites...
  //
  // Code is copied from our SRP "HotelCard".
  // -------------------------------------------------------------------
  const [cookies, setCookie] = useCookies(['userInformation']);
  const { getItem, setItem } = useSessionStorage();
  const userLocalStorageFavorites = getItem(sessionStorageKeys.USER_SESSION_FAVORITES);
  const { addHotelToFavorites } = useFavorites();
  const favorites = unionizeFavorites(
    userLocalStorageFavorites,
    cookies.userInformation?.favorites,
  );
  const [saveToFavorites, setSaveToFavorites] = useState<boolean>(false);
  const [userFavorites, setUserFavorites] = useState(favorites);
  const [showFavoriteToastMessage, setShowFavoriteToastMessage] = useState(false);
  const handleAddingHotelIdToFavorites = (hotel: HotelApi) => () =>
    addHotelToFavorites(
      cookies,
      setCookie,
      hotel,
      setShowFavoriteToastMessage,
      userFavorites,
      setSaveToFavorites,
      setItem,
      getItem,
      setUserFavorites,
    );
  // -------------------------------------------------------------------
  // UI Handlers -------------------------------------------------------
  // -------------------------------------------------------------------
  const handlePrevSlide =
    (
      swiper: MutableRefObject<SwiperType | undefined>,
      setIsBeginning: Dispatch<boolean>,
      setIsEnd: Dispatch<boolean>,
    ) =>
      () => {
        if (swiper?.current) {
          swiper.current.slidePrev();
          setIsBeginning(swiper.current.isBeginning);
          setIsEnd(swiper.current.isEnd);
        }
      };

  const handleNextSlide =
    (
      swiper: MutableRefObject<SwiperType | undefined>,
      setIsBeginning: Dispatch<boolean>,
      setIsEnd: Dispatch<boolean>,
    ) =>
      () => {
        if (swiper?.current) {
          swiper.current.slideNext();
          setIsBeginning(swiper.current.isBeginning);
          setIsEnd(swiper.current.isEnd);
        }
      };

  const handleSwiperInitAndChange =
    (
      swiperRef: MutableRefObject<SwiperType | undefined>,
      setIsBeginning: Dispatch<boolean>,
      setIsEnd: Dispatch<boolean>,
    ) =>
      (swiper: SwiperType) => {
      // eslint-disable-next-line no-param-reassign
        swiperRef.current = swiper;
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      };

  const handleCardClickRouting = (h: Hotel) => async () => {
    if (onHotelClick) {
      await onHotelClick(h);
    }
    router.push(`${RoutingPath.DLP}/${h.url}`);
  };

  return (
    <>
      {showFavoriteToastMessage && <FavoritesToastMessage saveToFavorites={saveToFavorites} />}
      <div
        ref={rootRef as RefObject<HTMLDivElement>}
        className={`w-full flex flex-col px-16px desktop:max-w-1312 desktop:mx-auto desktop:px-64px ${className}`}
      >
        <div className="w-full flex-shrink-0 text-20 flex justify-between desktop:text-24">
          <div>{title}</div>
          {/* ------------------------------------------------------------
          // Swiper Navigation (Mobile) ----------------------------------
          // -------------------------------------------------------------
          */}
          <div className="flex justify-center desktop:hidden">
            {showSeeAll && (
              <button type="button" className="text-14 underline" onClick={onSeeAll}>
                See All
              </button>
            )}
            <button
              type="button"
              className={cn({
                'ml-16px p-6px bg-solar-button-secondary hover:bg-solar-button-secondary-hover border border-solar-primary rounded-solar-sm':
                  true,
                hidden: isMobileSwiperBeginning || hotels.length < 2,
              })}
              onClick={handlePrevSlide(
                mobileSwiper,
                setIsMobileSwiperBeginning,
                setIsMobileSwiperEnd,
              )}
            >
              <Chevron className="w-16px h-16px" direction="left" />
            </button>

            <button
              type="button"
              className={cn({
                'p-6px ml-8px border border-solar-primary rounded-solar-sm': true,
                'bg-solar-button-secondary-disabled': isMobileSwiperEnd,
                'bg-solar-button-secondary hover:bg-solar-button-secondary-hover':
                  !isMobileSwiperEnd,
                hidden: hotels.length < 2,
              })}
              onClick={handleNextSlide(
                mobileSwiper,
                setIsMobileSwiperBeginning,
                setIsMobileSwiperEnd,
              )}
            >
              <Chevron className="w-16px h-16px" direction="right" />
            </button>
          </div>

          {/* ------------------------------------------------------------
          // Swiper Navigation (Desktop) ---------------------------------
          // -------------------------------------------------------------
          */}
          <div className="hidden desktop:flex items-center">
            {showSeeAll && (
              <button type="button" className="text-14 underline" onClick={onSeeAll}>
                See All
              </button>
            )}
            <button
              type="button"
              className={`${
                isDesktopSwiperBeginning ? 'hidden ' : ''
              }p-6px bg-solar-button-secondary hover:bg-solar-button-secondary-hover border border-solar-primary rounded-solar-sm ml-16px`}
              onClick={handlePrevSlide(
                desktopSwiper,
                setIsDesktopSwiperBeginning,
                setIsDesktopSwiperEnd,
              )}
            >
              <Chevron className="w-16px h-16px" direction="left" />
            </button>

            <button
              type="button"
              className={`${
                isDesktopSwiperEnd
                  ? 'bg-solar-button-secondary-disabled'
                  : 'bg-solar-button-secondary hover:bg-solar-button-secondary-hover'
              } ${
                desktopHotels.length < 2 ? 'hidden' : ''
              } p-6px ml-8px border border-solar-primary rounded-solar-sm`}
              onClick={handleNextSlide(
                desktopSwiper,
                setIsDesktopSwiperBeginning,
                setIsDesktopSwiperEnd,
              )}
            >
              <Chevron className="w-16px h-16px" direction="right" />
            </button>
          </div>
        </div>
        <div className="mt-16px">
          {/* ------------------------------------------------------------
          // Mobile Swiper --- -------------------------------------------
          // -------------------------------------------------------------
          */}
          <Swiper
            className="block desktop:hidden"
            slidesPerView="auto"
            spaceBetween={8}
            onSlideChange={handleSwiperInitAndChange(
              mobileSwiper,
              setIsMobileSwiperBeginning,
              setIsMobileSwiperEnd,
            )}
            onSwiper={handleSwiperInitAndChange(
              mobileSwiper,
              setIsMobileSwiperBeginning,
              setIsMobileSwiperEnd,
            )}
          >
            {hotels.map((h) => (
              <SwiperSlide style={{ width: '283px', alignSelf: 'flex-end' }} key={h.derived.id}>
                <HotelCard
                  name={h.name}
                  rating={h.rating}
                  reviews={h.reviews}
                  location={h.location}
                  vibe={h.vibe}
                  categories={h.productCategories}
                  images={h.hotelImages}
                  showFavorite
                  isHotSpot={h.isHotSpot}
                  hotSpotBadge={
                    h.derived.hotSpot && (
                      <>
                        <Image src={stopwatchIcon} alt="Stopwatch Icon" />
                        <span className="text-white text-12 leading-normal">
                          {getBadgeText(
                            parseISO(h.derived.hotSpot.booking_applicable_end_date),
                            h.derived.hotSpot as HotSpotMin,
                          )}
                        </span>
                      </>
                    )
                  }
                  isFavorited={userFavorites && userFavorites.includes(h.derived.id.toString())}
                  onFavorite={handleAddingHotelIdToFavorites(h.derived as HotelApi)}
                  onClick={handleCardClickRouting(h)}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* ------------------------------------------------------------
          // Desktop Swiper ----------------------------------------------
          // -------------------------------------------------------------
          */}
          <Swiper
            className="hidden desktop:block"
            onSwiper={handleSwiperInitAndChange(
              desktopSwiper,
              setIsDesktopSwiperBeginning,
              setIsDesktopSwiperEnd,
            )}
            onSlideChange={handleSwiperInitAndChange(
              desktopSwiper,
              setIsDesktopSwiperBeginning,
              setIsDesktopSwiperEnd,
            )}
          >
            {desktopHotels.map((dHotels) => (
              <SwiperSlide key={dHotels.map((d) => d.name).join('-')}>
                <div
                  className={`grid ${
                    hotels.length > 4 ? 'grid-rows-2' : 'grid-rows-1'
                  } grid-cols-4 gap-x-24px gap-y-16px items-end`}
                >
                  {dHotels.map((h) => (
                    <HotelCard
                      key={h.derived.id}
                      name={h.name}
                      rating={h.rating}
                      reviews={h.reviews}
                      location={h.location}
                      vibe={h.vibe}
                      categories={h.productCategories}
                      images={h.hotelImages}
                      showFavorite
                      isHotSpot={h.isHotSpot}
                      hotSpotBadge={
                        h.derived.hotSpot && (
                          <>
                            <Image src={stopwatchIcon} alt="Stopwatch Icon" />
                            <span className="text-white text-12 leading-normal">
                              {getBadgeText(
                                parseISO(h.derived.hotSpot.booking_applicable_end_date),
                                h.derived.hotSpot as HotSpotMin,
                              )}
                            </span>
                          </>
                        )
                      }
                      isFavorited={userFavorites && userFavorites.includes(h.derived.id.toString())}
                      onFavorite={handleAddingHotelIdToFavorites(h.derived as HotelApi)}
                      onClick={handleCardClickRouting(h)}
                    />
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

HomePageHotelCardCollection.defaultProps = {
  className: '',
  showSeeAll: false,
  onSeeAll: () => {},
  onHotelClick: () => {},
  innerRef: null,
};
