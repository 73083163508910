import React, { useEffect, useState, useContext } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import StaticDayPassesByLocation from '@components/DayPassesByLocation/StaticDayPassesByLocation';
import ExploreLocations from '@components/ExploreLocations/ExploreLocations';
import GiftCard from '@components/GiftCard/GiftCard';
import HotelPartner from '@components/HotelPartner/HotelPartner';
import CounterWithSearch from '@components/CounterWithSearch/CounterWithSearch';
import discover from '@assets/images/discover-daycations.svg';
import discoverMobile from '@assets/images/discover-daycations-mobile.svg';
import confident from '@assets/images/book-confidently.svg';
import confidentMobile from '@assets/images/book-confidently-mobile.svg';
import flexible from '@assets/images/flexible-support.svg';
import flexibleMobile from '@assets/images/flexible-support-mobile.svg';
import useIsMobile from '@hooks/useIsMobile';
import { useAmplitudeExperimentAB } from '@hooks/useAmplitudeExperiment';
import { PopularHotel } from '@customTypes/popular-hotel-data';
import { useEvents } from '@events/EventsProvider';
import useOnScreen from '@hooks/useOnScreen';
import useDoOnce from '@hooks/useDoOnce';
import { VIEW_HOMEPAGE, UseCaseMerchandisingEvents } from '@constants/amplitudeEvents';
import HotelApi from '@customTypes/hotel-api';
import { store } from '@context/store';
import { HomePageHotelsContext } from '@context/HomePageHotelsContext';
import MarketingModuleWithOverlappingImage from '@components/MarketingModule/MarketingModuleWithOverlappingImage';
import RoutingPath from '@constants/routingPath';
import HomePagePopularHotels from './HomePagePopularHotels';
import HomePageNearHotels from './HomePageNearHotels';
import HotelCardsLoadingSkeleton from './HotelCardsLoadingSkeleton';
import HomePageHotelCardCollection, { MapPopularHotels, MapHotelApi } from './HotelCardCollection';
import HomePageHandPickedCollection from './HandPickedCollection';

type IndexPageProps = {
  popularHotels: PopularHotel[];
  newAndNotableHotels: HotelApi[];
  userCityAndState: string;
};

export default function HomePage({
  popularHotels,
  newAndNotableHotels,
  userCityAndState,
}: IndexPageProps) {
  const router = useRouter();
  const [selectedCityShort, setSelectedCityShort] = useState<string>();
  const [amplitudePageViewEventSent, setAmplitudePageViewEventSent] = useState<boolean>(false);

  const { amplitude, track } = useEvents();

  const isMobile = useIsMobile();
  const { homePageHotels, isFetchComplete, isLoading, selectedCity, selectedCityId } =
    useContext(HomePageHotelsContext);

  const {
    state: { userLocation },
  } = useContext(store);

  const isUseCaseExperiment = useAmplitudeExperimentAB('use-case-merchandising') === 'treatment';

  useEffect(() => {
    if (homePageHotels && selectedCity && homePageHotels.length > 0) {
      const stateCodeValue = homePageHotels[0].code;
      if (stateCodeValue) {
        const city = `${selectedCity.split(',')[0]}, ${stateCodeValue}`;
        setSelectedCityShort(city);
      }
    }
  }, [homePageHotels, selectedCity]);

  useEffect(() => {
    if (!amplitudePageViewEventSent && amplitude && isFetchComplete) {
      const hotels: HotelApi[] | PopularHotel[] =
        homePageHotels.length > 0 ? homePageHotels : popularHotels;

      const hotSpotHotels = (hotels as (HotelApi | PopularHotel)[]).filter(
        (hotel) => !!hotel.hotSpot,
      );

      track(VIEW_HOMEPAGE, {
        hot_spot_hotels: hotSpotHotels.map((hotel) => hotel.name),
      });

      setAmplitudePageViewEventSent(true);
    }
  }, [
    amplitude,
    amplitudePageViewEventSent,
    homePageHotels,
    isFetchComplete,
    popularHotels,
    track,
  ]);

  // -------------------------------------------------------------------
  // Use-Case Collection Tracking --------------------------------------
  // -------------------------------------------------------------------
  const trackView = (eventName: string, options: any = {}) =>
    useDoOnce(() => {
      if (amplitude) {
        track(eventName, options);
      }
    });

  // UseCase Visibility Tracking Targets
  const [isNearCityCollectionVisible, nearCityCollectionRef] = useOnScreen();
  const [isPopularHotelsCollectionVisible, popularHotelsCollectionRef] = useOnScreen();
  const [isNewAndNotableCollectionVisible, newAndNotableCollectionRef] = useOnScreen();
  const [isHandPickedCollectionVisible, handPickedCollectionRef] = useOnScreen();

  // UseCase Visibility Tracking Events
  const viewNearCityCollectionEvent = trackView(
    UseCaseMerchandisingEvents.VIEW_HOME_PAGE_NEAR_CITY_COLLECTION,
  );
  const viewPopularHotelsCollectionEvent = trackView(
    UseCaseMerchandisingEvents.VIEW_HOME_PAGE_POPULAR_HOTELS_COLLECTION,
  );
  const viewNewAndNotableCollectionEvent = trackView(
    UseCaseMerchandisingEvents.VIEW_HOME_PAGE_NEW_AND_NOTABLE_COLLECTION,
  );
  const viewHandPickedCollectionEvent = trackView(
    UseCaseMerchandisingEvents.VIEW_HAND_PICKED_COLLECTION,
  );

  // Track any targets that are viewed
  useEffect(() => {
    if (isNearCityCollectionVisible) viewNearCityCollectionEvent();
    if (isPopularHotelsCollectionVisible) viewPopularHotelsCollectionEvent();
    if (isNewAndNotableCollectionVisible) viewNewAndNotableCollectionEvent();
    if (isHandPickedCollectionVisible) viewHandPickedCollectionEvent();
  }, [
    isNearCityCollectionVisible,
    isPopularHotelsCollectionVisible,
    isNewAndNotableCollectionVisible,
    isHandPickedCollectionVisible,
    viewNearCityCollectionEvent,
    viewPopularHotelsCollectionEvent,
    viewNewAndNotableCollectionEvent,
    viewHandPickedCollectionEvent,
  ]);

  // -------------------------------------------------------------------
  // Use-Case Collection UI Handlers -----------------------------------
  // -------------------------------------------------------------------
  const handleSeeAllNearCity = async () => {
    if (userLocation) {
      if (amplitude) {
        try {
          await track(UseCaseMerchandisingEvents.HOME_PAGE_NEAR_CITY_COLLECTION_SEE_ALL_CLICK, {});
        } catch (e) {
          // continue if tracking fails
        }
      }
      window.sessionStorage.removeItem('smart_calendar_date');
      window.sessionStorage.removeItem('srp_filters');
      window.sessionStorage.removeItem('show_only_available_filter');
      router.push(userLocation.url);
    }
  };

  const handleSeeAllNewAndNotable = async () => {
    if (amplitude) {
      try {
        await track(
          UseCaseMerchandisingEvents.HOME_PAGE_NEW_AND_NOTABLE_COLLECTION_SEE_ALL_CLICK,
          {},
        );
      } catch (e) {
        // continue if tracking fails
      }
    }
    window.sessionStorage.removeItem('smart_calendar_date');
    window.sessionStorage.removeItem('srp_filters');
    window.sessionStorage.removeItem('show_only_available_filter');
    router.push(`${RoutingPath.SRP}?shownew=true&nodate=true`);
  };

  const trackHotelClick = async (eventName: string, data: any = {}) => {
    try {
      await track(eventName, data);
    } catch (e) {
      // continue if tracking fails
    }
  };

  const trackHandPickedCollectionClick = async ({ title }: { title: string }) => {
    if (amplitude) {
      try {
        await track(UseCaseMerchandisingEvents.HAND_PICKED_COLLECTION_CLICK, { name: title });
      } catch (e) {
        // continue of tracking fails
      }
    }
  };

  const defaultPageTitle = 'ResortPass | Book a Day Pass to a Hotel or Resort starting at only $25';

  return (
    <>
      <Head>
        <title>{defaultPageTitle}</title>
        <meta property="og:title" content={defaultPageTitle} />
        <meta
          name="description"
          content="Book a hotel pool or spa day pass, starting at just $25. Enjoy 1,500+ world-class resorts, without the overnight stay. No membership required."
        />
      </Head>
      <main className="flex flex-col">
        <div className="flex flex-col">
          <div className="flex max-w-8xl mx-auto py-14 gap-10 flex-col pl-3 pr-4 d:max-w-8xl d:flex-row d:px-6">
            <div className="flex items-center">
              <div className="relative h-10 w-24 mb-1 pl-2 d:pl-0">
                <Image src={isMobile ? discoverMobile : discover} alt="discover" />
              </div>
              <div className="flex-col d:ml-4">
                <p className="font-rp-pn-semi-bold text-base">Discover Daycations</p>
                <p className="font-rp-pn-regular leading-20px text-sm">
                  Select a date and explore pool, spa, beach access and more, at 1,000+ top hotels.
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="relative h-10 w-24 mb-1 pl-2 d:pl-0">
                <Image src={isMobile ? confidentMobile : confident} alt="confident" />
              </div>
              <div className="flex-col d:ml-4">
                <p className="font-rp-pn-semi-bold text-base">Book Confidently</p>
                <p className="font-rp-pn-regular leading-20px text-sm">
                  After booking, receive check-in instructions, parking details, and all necessary
                  information.
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="relative h-10 w-24 mb-1 pl-2 d:pl-0">
                <Image src={isMobile ? flexibleMobile : flexible} alt="discover" />
              </div>
              <div className="flex-col d:ml-4">
                <p className="font-rp-pn-semi-bold text-base">Flexible Support and Cancellation</p>
                <p className="font-rp-pn-regular leading-20px text-sm">
                  Invite guests or cancel bookings as needed with support on our website or app.
                </p>
              </div>
            </div>
          </div>
        </div>

        {isLoading ? <HotelCardsLoadingSkeleton /> : null}

        {isUseCaseExperiment ? (
          <>
            {!isLoading && homePageHotels && homePageHotels.length === 0 && popularHotels && (
              <HomePageHotelCardCollection
                innerRef={popularHotelsCollectionRef}
                title="Popular Hotels"
                hotels={MapPopularHotels(popularHotels.slice(0, 16))}
                onHotelClick={(h) =>
                  trackHotelClick(
                    UseCaseMerchandisingEvents.HOME_PAGE_POPULAR_HOTELS_COLLECTION_CLICK,
                    { name: h.name, url: h.url },
                  )}
              />
            )}

            {!isLoading && homePageHotels && homePageHotels.length > 0 ? (
              <HomePageHotelCardCollection
                innerRef={nearCityCollectionRef}
                title={`Near ${userCityAndState}`}
                hotels={MapHotelApi(homePageHotels)}
                showSeeAll
                onSeeAll={handleSeeAllNearCity}
                onHotelClick={(h) =>
                  trackHotelClick(UseCaseMerchandisingEvents.HOME_PAGE_NEAR_CITY_COLLECTION_CLICK, {
                    name: h.name,
                    url: h.url,
                  })}
              />
            ) : null}
          </>
        ) : (
          <>
            {!isLoading && homePageHotels && homePageHotels.length === 0 && popularHotels && (
              <HomePagePopularHotels popularHotels={popularHotels} />
            )}

            {!isLoading && homePageHotels && homePageHotels.length > 0 ? (
              <HomePageNearHotels
                hotels={homePageHotels}
                selectedCityId={selectedCityId}
                selectedCityShort={selectedCityShort}
                userCityAndState={userCityAndState}
              />
            ) : null}
          </>
        )}

        {isUseCaseExperiment && userLocation ? (
          <HomePageHandPickedCollection
            innerRef={handPickedCollectionRef}
            className="mt-32px"
            srpURL={`${window.location.origin}${userLocation.url}`}
            onCollectionClick={trackHandPickedCollectionClick}
          />
        ) : null}

        {isUseCaseExperiment && newAndNotableHotels && newAndNotableHotels.length > 0 ? (
          <HomePageHotelCardCollection
            title="New and notable"
            innerRef={newAndNotableCollectionRef}
            className="mt-32px"
            hotels={MapHotelApi(newAndNotableHotels)}
            showSeeAll
            onSeeAll={handleSeeAllNewAndNotable}
            onHotelClick={(h) =>
              trackHotelClick(
                UseCaseMerchandisingEvents.HOME_PAGE_NEW_AND_NOTABLE_COLLECTION_CLICK,
                { name: h.name, url: h.url },
              )}
          />
        ) : null}

        <div className="px-6 mt-20 mb-10">
          <MarketingModuleWithOverlappingImage />
        </div>

        <div className="pt-10">
          <ExploreLocations />
        </div>
        <GiftCard />
        <HotelPartner />
        <CounterWithSearch />
        <StaticDayPassesByLocation />
      </main>
    </>
  );
}
