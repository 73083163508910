const actionTypes = {
  APPLY_SEARCH_FILTER: 'APPLY_SEARCH_FILTER',
  AVAILABLE_SEARCH_FILTER_OPTIONS: 'AVAILABLE_SEARCH_FILTER_OPTIONS',
  CITY_SEO_PAGE: 'CITY_SEO_PAGE',
  OPEN_DATE_PICKER: 'OPEN_DATE_PICKER',
  OPEN_FILTERS: 'OPEN_FILTERS',
  OPEN_SAME_DAY_BOOKING_WARNING: 'OPEN_SAME_DAY_BOOKING_WARNING',
  SET_BFCM_PROMO_FLAG: 'SET_BFCM_PROMO_FLAG',
  SET_EDUCATIONAL_MODAL_OPEN: 'SET_EDUCATIONAL_MODAL_OPEN',
  SET_EDUCATIONAL_MODULE_EXPERIMENT: 'SET_EDUCATIONAL_MODULE_EXPERIMENT',
  SET_FIXED_DATE_SELECTOR_EXPERIMENT: 'SET_FIXED_DATE_SELECTOR_EXPERIMENT',
  SET_GUEST_GROUPS_BAG: 'SET_GUEST_GROUPS_BAG',
  SET_HIDE_AMENITIES_EXPERIMENT: 'SET_HIDE_AMENITIES_EXPERIMENT',
  SET_HOMEPAGE_STILL_EXPERIMENT: 'SET_HOMEPAGE_STILL_EXPERIMENT',
  SET_PRICING_MARKDOWN_EXPERIMENT: 'SET_PRICING_MARKDOWN_EXPERIMENT',
  SET_PRODUCTS_PHOTO_AB_TEST: 'SET_PRODUCTS_PHOTO_AB_TEST',
  SET_SEARCH_DATE: 'SET_SEARCH_DATE',
  SET_SELECTED_TIME_SLOT: 'SET_SELECTED_TIME_SLOT',
  SET_SMART_CALENDAR_PRODUCT_FILTER: 'SET_SMART_CALENDAR_PRODUCT_FILTER',
  SET_SPA_BANNER_AB_TEST: 'SET_SPA_BANNER_AB_TEST',
  SET_TIME_SLOT_GUEST_BAG: 'SET_TIME_SLOT_GUEST_BAG',
  SET_USER_LOCATION: 'SET_USER_LOCATION',
  SHOW_PAST_DATE_SELECTED_ERROR: 'SHOW_PAST_DATE_SELECTED_ERROR',
  TOGGLE_EDUCATIONAL_MODAL: 'TOGGLE_EDUCATiONAL_MODAL',
  TOGGLE_ERROR_POPUP: 'TOGGLE_ERROR_POPUP',
  TOGGLE_ONLY_SHOW_AVAILABLE_HOTELS: 'TOGGLE_ONLY_SHOW_AVAILABLE_HOTELS',
  UPDATE_ACCEPT_COOKIES: 'UPDATE_ACCEPT_COOKIES',
  UPDATE_DEFAULT_CURRENCY: 'UPDATE_DEFAULT_CURRENCY',
  UPDATE_FILTER_COUNT: 'UPDATE_FILTER_COUNT',
  UPDATE_NUMBER_OF_CART_ITEMS: 'UPDATE_NUMBER_OF_CART_ITEMS',
  UPDATE_REFERER: 'UPDATE_REFERER',
  UPDATE_SEARCH_RESULTS: 'UPDATE_SEARCH_RESULTS',
  UPDATE_SEARCHED_LOCATION: 'UPDATE_SEARCHED_LOCATION',
  UPDATE_SELECTED_CURRENCY: 'UPDATE_SELECTED_CURRENCY',
  UPDATE_SHOW_COOKIE_CONSENT_BANNER: 'UPDATE_SHOW_COOKIE_CONSENT_BANNER',
  UPDATE_SRP_DATA: 'UPDATE_SRP_DATA',
  UPDATE_SRP_SCROLL_POSITION: 'UPDATE_SRP_SCROLL_POSITION',
  UPDATE_WINDOW_URL: 'UPDATE_WINDOW_URL',
  USER_CITY_AND_STATE: 'USER_CITY_AND_STATE',
};

export default actionTypes;
