/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getPopularHotels, getNewAndNotableHotels } from '@utils/services';
import { useCookies } from 'react-cookie';
import useSessionStorage from '@hooks/useSessionStorage';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { homePageView } from '@events/HomePage';
import HomePage from '@components/HomePage/HomePage';
import { PopularHotel } from '@customTypes/popular-hotel-data';
import HotelApi from '@customTypes/hotel-api';
import { store } from '@context/store';
import Layout from '@components/Layout/Layout';
import { useRouter } from 'next/router';
import Alert from '@solar/common/Alert/Alert';

type IndexPageProps = {
  popularHotels: PopularHotel[];
  newAndNotableHotels: HotelApi[];
};

const getPopularHotelsData = async () =>
  getPopularHotels()
    .then((response) => ({
      showPopularHotels: true,
      popularHotels: response?.hotels,
    }))
    .catch(() => ({
      showPopularHotels: false,
      popularHotels: null,
    }));

export async function getServerSideProps() {
  const [popularHotels, newAndNotableHotels] = await Promise.all([
    getPopularHotelsData(),
    getNewAndNotableHotels(),
  ]);

  return {
    props: {
      ...popularHotels,
      newAndNotableHotels,
    },
  };
}

export default function Home({ popularHotels, newAndNotableHotels }: IndexPageProps) {
  const [homePageViewFired, setHomePageViewFired] = useState<boolean>(false);

  const { getItem } = useSessionStorage();
  const date = getItem(sessionStorageKeys.SMART_CALENDAR_DATE);
  const sessionID = getItem(sessionStorageKeys.SESSION_ID);
  const [cookies] = useCookies();
  const router = useRouter();

  const {
    state: { userCityAndState },
  } = useContext(store);

  // GA4 Page View Event
  useEffect(() => {
    if (!homePageViewFired && sessionID) {
      homePageView(cookies.userInformation, sessionID, date);
      setHomePageViewFired(true);
    }
  }, [cookies.userInformation, date, homePageViewFired, sessionID]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);

  const showToast = useCallback(
    (type: 'success' | 'error', message: string = '') => {
      setToast({ message, type });
    },
    [setToast],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { update_password_success, ...rest } = router.query;
    if (update_password_success) {
      showToast('success', 'New password saved successfully');
      router.replace(
        {
          pathname: router.pathname,
          query: rest,
        },
        undefined,
        { shallow: true },
      );
    }
  }, [router, router.query.update_password_success, showToast]);

  return (
    <>
      {toast && (
        <Alert
          message={toast.message}
          type={toast.type}
          onClose={() => {
            setToast(null);
          }}
        />
      )}
      <HomePage
        popularHotels={popularHotels}
        newAndNotableHotels={newAndNotableHotels}
        userCityAndState={userCityAndState}
      />
    </>
  );
}

Home.getLayout = (page: React.ReactElement) => <Layout searchBarEnabled={false}>{page}</Layout>;
