import React, { useRef, useState, RefObject } from 'react';
import Image from '@components/common/Image/ImageFallback';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper';
import Chevron from '@solar/assets/images/directional/chevron';
import cn from '@helpers/className';
import { getCdnImageUrl } from '../../utils/cloudflare';
import 'swiper/css';

// ---------------------------------------------------------------------
// Hack to set the search filter  --------------------------------------
// ---------------------------------------------------------------------
// App currently looks at a sessionstorage key named "srp_filters"
// that contains a structure similar to the one below.
// This struct is then used to construct the state of the filters
// and to perform a filtered search.
//
// Until we refactor the search results page to be stateless,
// (ie: not depend on the presence of this struct for filtering and searching)
// we will link users to "filtered" SRP pages by first modifying and saving the
// struct to sessionstorage, then redirect/reload the user onto the SRP.
//
// Note: that this of course erases the parameters of the previous search
// the user may have done.
//
// Another Note: All of the parameters(key/values) in the struct is required.
// Our search is so fragile that any parameter missing will produce an
// application error.
// ---------------------------------------------------------------------
const SRPFiltersStruct = {
  url: '', // eg: https://www.resortpass.com/hotel-day-passes/Phoenix-12',
  amenities: {
    'all-inclusive': false,
    'fitness-center': false,
    'rooftop-pool': false,
    beach: false,
    spa: false,
    hottub: false,
    lazyriver: false,
    waterslide: false,
  },
  star_classification: {
    any: false,
    '=5': false,
    '>=4': false,
    '>=3': false,
  },
  guest_rating: {
    '>=3.8': false,
  },
  hotel_vibes: {
    'family-friendly': false,
    party: false,
    serene: false,
    luxe: false,
    trendy: false,
  },
};

const constructFiltersObject = (url: string, params: any) => {
  // clone the SRPFiltersStruct as the basis of the new struct
  const target: any = JSON.parse(JSON.stringify(SRPFiltersStruct));
  target.url = url;

  // copy param values into the new object
  Object.keys(params).forEach((k) => {
    target[k] = target[k] || {};
    Object.assign(target[k], params[k]);
  });
  return target;
};

const buildSrpUrl = (srpUrl: string, productTypeIds: Array<number> = []) => {
  const url = new URL(srpUrl);
  url.searchParams.append('nodate', 'true');
  if (productTypeIds.length) {
    url.searchParams.append('pid', productTypeIds.join(','));
  }
  return url.toString();
};

type Guide = {
  title: string;
  imageUrl: string;
  srpURL: string;
  srpParams: any;
};

type Props = {
  innerRef?: RefObject<HTMLElement>;
  className?: string;
  srpURL: string;
  onCollectionClick?: (g: Guide) => void | Promise<void>;
};

export default function HomePageHandPickedCollection({
  srpURL,
  className,
  innerRef,
  onCollectionClick,
}: Props) {
  const rootRef = innerRef || useRef(null);
  // -------------------------------------------------------------------
  // Component State ---------------------------------------------------
  // -------------------------------------------------------------------
  const swiperInstance = useRef<SwiperType>();
  const [isSwiperBeginning, setIsSwiperBeginning] = useState(true);
  const [isSwiperEnd, setIsSwiperEnd] = useState(false);
  const guides: Array<Guide> = [
    {
      title: 'Family Fun',
      imageUrl: 'https://static-assets.resortpass.com/assets/home-v2/use-case/use_case_family.jpg',
      srpURL: buildSrpUrl(srpURL),
      srpParams: {
        hotel_vibes: {
          'family-friendly': true,
        },
      },
    },
    {
      title: '5 Star Luxury',
      imageUrl: 'https://static-assets.resortpass.com/assets/home-v2/use-case/use_case_5_star.jpg',
      srpURL: buildSrpUrl(srpURL),
      srpParams: {
        star_classification: {
          '=5': true,
        },
        hotel_vibes: {
          luxe: true,
        },
      },
    },
    {
      title: 'Wellness',
      imageUrl: 'https://static-assets.resortpass.com/assets/home-v2/use-case/use_case_spa.jpg',
      // spa pass, massage, spa-treatment, facial, nails, couples spa
      srpURL: buildSrpUrl(srpURL, [3, 7, 50, 51, 52, 53]),
      srpParams: {},
    },
    {
      title: 'Bach and Birthdays',
      imageUrl: 'https://static-assets.resortpass.com/assets/home-v2/use-case/use_case_party.jpg',
      srpURL: buildSrpUrl(srpURL, [1]), // cabana
      srpParams: {
        hotel_vibes: {
          party: true,
        },
      },
    },
  ];

  // -------------------------------------------------------------------
  // UI Handlers -------------------------------------------------------
  // -------------------------------------------------------------------
  const handlePrevSlide = () => {
    if (swiperInstance.current) {
      swiperInstance.current.slidePrev();
      setIsSwiperBeginning(swiperInstance.current.isBeginning);
      setIsSwiperEnd(swiperInstance.current.isEnd);
    }
  };

  const handleNextSlide = () => {
    if (swiperInstance.current) {
      swiperInstance.current.slideNext();
      setIsSwiperBeginning(swiperInstance.current.isBeginning);
      setIsSwiperEnd(swiperInstance.current.isEnd);
    }
  };

  const handleSlideChange = (swiper: SwiperType) => {
    setIsSwiperBeginning(swiper.isBeginning);
    setIsSwiperEnd(swiper.isEnd);
  };

  const handleRouteChange = (g: (typeof guides)[number]) => async () => {
    if (onCollectionClick) await onCollectionClick(g);
    const srpParams = constructFiltersObject(g.srpURL, g.srpParams);
    window.sessionStorage.setItem('srp_filters', JSON.stringify(srpParams));
    window.sessionStorage.removeItem('show_only_available_filter');
    window.sessionStorage.removeItem('smart_calendar_date');
    window.location.href = g.srpURL;
  };

  return (
    <div
      className={`w-full font-rp-basetica desktop:bg-solar-surface-secondary ${className}`}
      ref={rootRef as RefObject<HTMLDivElement>}
    >
      <div className="p-16px desktop:max-w-1312 desktop:px-64px mx-auto desktop:py-58px">
        {/* -------------------------------------------------------------
        // Desktop Navigation Buttons ----------------------------------
        // -------------------------------------------------------------
        */}

        <div className="hidden desktop:flex justify-end mb-24px">
          <button
            type="button"
            className={`${
              isSwiperBeginning ? 'invisible' : ''
            } p-6px bg-solar-button-secondary hover:bg-solar-button-secondary-hover border border-solar-primary rounded-solar-sm`}
            onClick={handlePrevSlide}
          >
            <Chevron className="w-16px h-16px" direction="left" />
          </button>

          <button
            type="button"
            className={`${
              isSwiperEnd
                ? 'bg-solar-button-secondary-disabled'
                : 'bg-solar-button-secondary hover:bg-solar-button-secondary-hover'
            } p-6px ml-8px border border-solar-primary rounded-solar-sm`}
            onClick={handleNextSlide}
          >
            <Chevron className="w-16px h-16px" direction="right" />
          </button>
        </div>

        {/* -------------------------------------------------------------
        // Handpicked Content ------- ----------------------------------
        // -------------------------------------------------------------
        */}
        <div className="flex flex-col desktop:flex-row">
          <div className="flex-shrink-0 pr-3" style={{ flexBasis: 'calc(50% - 70px' }}>
            <h3 className="text-20 desktop:text-24 -tracking-0.5 text-solar-primary">
              Hand-picked by experts
            </h3>
            <p className="hidden desktop:block mt-24px">
              Dive into our curated guides, where hospitality experts unveil unforgettable daytime
              experiences. Discover new hotels and spend the day at their pool, spa, restaurant, and
              more.
            </p>
          </div>
          <div className="flex-1 desktop:pl-3 overflow-hidden relative mt-16px desktop:mt-0">
            <div
              className="hidden desktop:block absolute h-full z-10"
              style={{
                backgroundImage: 'linear-gradient(to right, #F1F5FE, #F1F5FE 60%, transparent)',
                width: '80px',
              }}
            />
            <Swiper
              slidesPerView="auto"
              onSwiper={(swiper) => {
                swiperInstance.current = swiper;
                setIsSwiperBeginning(swiper.isBeginning);
                setIsSwiperEnd(swiper.isEnd);
              }}
              onSlideChange={handleSlideChange}
            >
              <SwiperSlide
                className="hidden desktop:block"
                style={{ width: '80px', marginLeft: '-24px' }}
              />
              {guides.map((g, index) => (
                <SwiperSlide
                  style={{ width: '168px' }}
                  key={g.title}
                  className={cn({
                    'desktop:ml-24px': true,
                    'ml-8px': index !== 0,
                  })}
                >
                  <button type="button" onClick={handleRouteChange(g)}>
                    <div className="w-168 h-168">
                      <Image
                        width={168}
                        height={168}
                        alt={g.title}
                        src={getCdnImageUrl(168, g.imageUrl, '', 75)}
                      />
                    </div>
                    <div className="mt-8px text-left">{g.title}</div>
                  </button>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

HomePageHandPickedCollection.defaultProps = {
  className: '',
  innerRef: null,
  onCollectionClick: null,
};
